<div id="subscription" class="page-column column fill-space overflow-hidden">
  <div class="header row">
    <div class="fill-space row gap-1">
      <div class="title">Subscription Plan</div>
      <mat-spinner *ngIf="subscriptionLoading.value !== loadingState.Ready" diameter="20"> ></mat-spinner>
    </div>
  </div>

  <div class="subscription-content column scrollbar">
    <ng-container *ngIf="selectedPlans$ | async as selectedPlans">
      <ng-container *ngIf="selectedPlans.length > 0">
        <div class="subscription-section column">
          <div class="subscription-section-title">Active plan</div>
          <div class="row flex fill-space-equal">
            <div>Your selected plan.</div>
            <ng-container *ngIf="subscriptionLoading.value === loadingState.WaitingForUpdate">
              <div class="flex-grow-1"></div>
              <div>
                <i>Please allow some time for your plan update to be reflected here.</i>
              </div>
            </ng-container>
          </div>
          <div class="plan-box flex-grow-1 center space-top-1" *ngFor="let plan of selectedPlans">
            <h2>Active plan</h2>
            <div>{{ plan.users }} Users</div>
            <div>{{ plan.credits }} Credits/month</div>
            <button class="button button-action button-action-long" (click)="goToPortal()">View invoices</button>
          </div>
        </div>
      </ng-container>

      <div class="subscription-section column">
        <div class="subscription-section-title">Plans</div>
        <div>Choose the plan that fits your needs.</div>
        <div class="subscription-section-content">
          <div class="title">Users</div>
          <div class="fill-space row flex">
            <mat-slider [min]="minUsers" [max]="maxUsers" step="1" discrete>
              <input matSliderThumb [(value)]="usersSelected" />
            </mat-slider>
            <div matTextSuffix class="slider-text center">{{ usersSelected }} Users</div>
          </div>

          <div class="title">Credits</div>
          <div class="fill-space row flex">
            <mat-slider [min]="minCredits" [max]="maxCredits" [step]="creditsStep" discrete>
              <input matSliderThumb [(value)]="creditsSelected" />
            </mat-slider>
            <div matTextSuffix class="slider-text center">{{ creditsSelected }} Credits</div>
          </div>

          <div class="plan-buttons row wrap fill-space-equal gap-4">
            <div class="plan-box flex-grow-1 center" *ngFor="let plan of planSuggestions">
              <h2>{{ plan.name }}</h2>
              <div>{{ plan.users }} Users</div>
              <div>{{ plan.credits }} Credits/month</div>
              <button
                [disabled]="subscriptionLoading.value !== loadingState.Ready"
                (click)="selectSuggestedSubscription(plan)"
                [class]="{
                  button: true,
                  'button-action-long': true,
                  'button-grey': subscriptionLoading.value !== loadingState.Ready,
                  'button-action': subscriptionLoading.value === loadingState.Ready
                }">
                Select
              </button>
            </div>
          </div>
          <div class="subscription-info column">
            <ng-container *ngIf="usersProductPriceInCents$ | async as usersPrice">
              <ng-container *ngIf="creditsProductPriceInCents$ | async as creditsPrice">
                <div class="row fill-space">
                  <div>Monthly</div>
                  <div class="fill-space"></div>
                  <div class="title">
                    {{ usersSelected }} users * {{ usersPrice / 100 }}€ + {{ creditsSelected / creditsStep }}(x{{ creditsStep }}) credits *
                    {{ (creditsPrice / 100) * creditsStep }}€ = {{ (usersSelected * usersPrice + creditsSelected * creditsPrice) / 100 }}€
                  </div>
                </div>
                <div class="row fill-space">
                  <div>Yearly</div>
                  <div class="fill-space"></div>
                  <div class="title">{{ ((usersSelected * usersPrice + creditsSelected * creditsPrice) * 12) / 100 }}€</div>
                </div>
              </ng-container>
            </ng-container>
            <button
              [class]="{
                button: true,
                'button-action-long': true,
                'button-grey': subscriptionLoading.value !== loadingState.Ready,
                'button-action': subscriptionLoading.value === loadingState.Ready
              }"
              (click)="subscribeToPlan()"
              [disabled]="subscriptionLoading.value !== loadingState.Ready">
              {{ selectedPlans.length > 0 ? 'Update plan' : 'Subscribe to plan' }}
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="selectedPlans.length > 0" class="cancel-plan-link row row-center-right space-right-1" (click)="goToPortal()">
        <div>Cancel plan</div>
      </div>
    </ng-container>
  </div>
</div>
